import {Card, Page} from '@shopify/polaris-internal';
import {useI18n} from '@shopify/react-i18n';
import React from 'react';

import {
  PageFooterHelp,
  SkeletonIndexFilters,
  SkeletonTable,
} from '~/components';
import {createI18nConfig} from '~/utils/createI18nConfig';
import {searchURL, synonymGroupURL} from '~/utils/urls';

import translations from './translations';

export function SynonymGroupListSkeleton() {
  const [i18n] = useI18n(createI18nConfig(translations));
  return (
    <Page
      title={i18n.translate('SynonymGroupList.title')}
      primaryAction={{
        content: i18n.translate('SynonymGroupList.actions.createGroup'),
        url: synonymGroupURL('new'),
      }}
      backAction={{
        content: i18n.translate('SynonymGroupList.navigation.search'),
        url: searchURL(),
      }}
    >
      <Card padding="0">
        <SkeletonIndexFilters />
        <SkeletonTable numColumns={2} />
      </Card>

      <PageFooterHelp resourceName="synonyms" />
    </Page>
  );
}
